import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Button, Tooltip, Card } from 'tunggal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Tooltip`}</inlineCode>{` merupakan sebuah teks berisi informasi biasanya memberikan informasi mengenai unsur UI atau fitur yang terkait muncul ketika user berinteraksi dengan unsur UI, tooltip`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Card bordered mdxType="Card">
  <Row mdxType="Row">
    <Column colMd={3} colLg={3} mdxType="Column">
      <Tooltip placement="top" text="Tooltip" mdxType="Tooltip">
        <Button color="neutral" size="md" type="button" mdxType="Button">
          Placement Top
        </Button>
      </Tooltip>
    </Column>
    <Column colMd={3} colLg={3} mdxType="Column">
      <Tooltip placement="right" text="Tooltip" mdxType="Tooltip">
        <Button color="neutral" size="md" type="button" mdxType="Button">
          Placement Right
        </Button>
      </Tooltip>
    </Column>
    <Column colMd={3} colLg={3} mdxType="Column">
      <Tooltip placement="bottom" text="Tooltip" mdxType="Tooltip">
        <Button color="neutral" size="md" type="button" mdxType="Button">
          Placement Bottom
        </Button>
      </Tooltip>
    </Column>
    <Column colMd={3} colLg={3} mdxType="Column">
      <Tooltip placement="left" text="Tooltip" mdxType="Tooltip">
        <Button color="neutral" size="md" type="button" mdxType="Button">
          Placement Left
        </Button>
      </Tooltip>
    </Column>
  </Row>
    </Card>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      